import {usePage} from '@inertiajs/react';
import {Rates, LoadProfiles, Gear} from '../assets/icons/icons';
import {classNames} from '../utils/utils';
import Logo from '../assets/images/coned-logo.png';
import {CurrencyDollarIcon} from '@heroicons/react/24/outline';

const navigation = [
  {name: 'Rates', href: '/rate_templates', icon: Rates, paths: ['rate_templates', 'custom_rates']},
  {name: 'Load Profiles', href: '/load-profiles', icon: LoadProfiles, paths: ['load-profiles']},
  {
    name: 'Market Rates',
    href: '/price_profiles',
    icon: CurrencyDollarIcon,
    paths: ['price_profiles'],
  },
  {name: 'Settings', href: '/settings', icon: Gear},
];

const LayoutWrapper = ({children}) => {
  const {url} = usePage();

  return (
    <>
      <div className="h-full bg-gray-900">
        <div className="fixed inset-y-0 z-50 flex w-[250px] flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex h-16 shrink-0 items-center mt-3">
              <a href="/rate_templates">
                <img className="h-[46px]" src={Logo} alt="ConEd logo" />
              </a>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.href === url || item.paths?.some((path) => url.includes(path))
                              ? 'bg-gray-600 text-gray-200'
                              : 'text-gray-200 hover:bg-gray-600',
                            'group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold items-center'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === url
                                ? 'text-white'
                                : 'text-gray-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="-mx-6 mt-auto p-3">
                  <a
                    href="/settings"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white border-t border-t-gray-600"
                  >
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <div className="flex flex-col">
                      <span>Con Edison Admin</span>
                      <span className="text-xs font-light">steven@coned.com</span>
                    </div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <main className="pl-[250px] h-full w-full fixed">
          <div className="rounded-[40px] rounded-r-none bg-white mt-3 h-[calc(100%-12px)] shadow-md overflow-scroll">
            <div className="px-8 py-6">{children}</div>
          </div>
        </main>
      </div>
    </>
  );
};

export default function Layout(page) {
  return <LayoutWrapper>{page}</LayoutWrapper>;
}
