import React from 'react';
import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
//import 'bootstrap/dist/css/bootstrap.css';
import Layout from '../layout/Layout';
import '../styles/application.css';

document.addEventListener('DOMContentLoaded', () => {
  const app = document.getElementById('app');
  const root = createRoot(app);

  createInertiaApp({
    resolve: async (name) => {
      const pages = import.meta.glob('../Pages/**/*.jsx');
      let Page = await pages[`../Pages/${name}/index.jsx`]();

      Page.default.layout = Page.default.layout || Layout;
      return Page;
    },

    setup({App, props}) {
      root.render(<App initialPage={JSON.parse(app.dataset.page)} {...props} />);
    },
  });
});
